import React from "react";

import Footer from "../components/footer/footer";
import MainBanner from "../components/main-banner";
import YouTube from "react-youtube";

const videos = [
  {
    boardVersion: "1",
    description: `The original PixelPi was designed for a CNIT 315 final project.
    The requisite programs ran both on a desktop pc and an Arduino Uno and displayed colors to a 32x32 matrix.`,
    improvements: `N/A`,
    videoId: "4KQzOLRS2EY",
    technologiesUsed: "Arduino, sockets, Java",
    backgroundGif: "url('/pixel-pi-background-v1.gif')",
    timeframe: "Spring 2017",
  },
  {
    boardVersion: "2",
    videoId: "zVbTPZip60c",
    description: `Version 2 consisted of a Raspberry Pi 3B and a 64x32 LED matrix.
    The display portion was refactored in Python, while the audio processing remained in Java & Processing.`,
    improvements: `This iteration was the first to offer a standalone set, where only the RPi & LED matrix were needed in order to work.
    It also featured a clock that would automatically start if no music was playing.`,
    technologiesUsed: "Raspberry Pi, Java, Python",
    backgroundColor: "rgba(128, 0, 0, 0.2)",
    backgroundGif: "url('/pixel-pi-background-v2.gif')",
    timeframe: "Summer 2017 - Fall 2017",
  },
  {
    boardVersion: "3",
    description: `V3 was built with 8 64x32 led matrices.
    Cython & multiprocessing were also required here in order to drive the 393216 pixels per second (64 pixels x 256 pixels x 24 Hz).`,
    improvements: `The v3 matrix consisted of 8x the amount of pixels as it's predecessor.
    Additionally, MQTT replaced the lower-level socket connections from previous versions, which turned out to be a fantastic addition.`,
    videoId: "jqmwNiv9g-A",
    technologiesUsed:
      "Raspberry Pi, Kotlin, Python, Cython, MQTT, multiprocessing, virtual environments",
    backgroundGif: "url('/pixel-pi-background-v3.gif')",
    timeframe: "Winter 2017 - Fall 2020",
  },
  {
    boardVersion: "4",
    description: `This iteration is an approximately 1'x4' wood board spray-painted titanium white.
    154 30mm holes were then drilled out to make room for WS2801 leds.`,
    improvements: `This board featured an original design, the first out of any PixelPi board.
    The light from the board also seems much more natural, similar to light coming from a fireplace.`,
    videoId: "ZTfDSOyTaH8",
    technologiesUsed: "Python, CAD - also learned woodworking & painting",
    backgroundGif: "url('/pixel-pi-background-v4.gif')",
    timeframe: "Winter 2020 - Present",
  },
];

class YouTubeVideo extends React.Component {
  constructor(props) {
    super();
    this.state = {
      videoId: props.videoId,
    };
    console.log(this.state.videoId);
  }

  render() {
    const opts = {
      height: "390",
      width: "640",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    console.log("video id - " + this.state.videoId);

    return (
      <YouTube
        videoId={this.state.videoId}
        opts={opts}
        onReady={this._onReady}
      />
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

const Iteration = (props) => {
  const video = props.video;
  const style = {
    backgroundColor: video.backgroundColor,
    backgroundImage: video.backgroundGif,
    backgroundSize: "cover",
  };

  return (
    <div className="container pp-version text-left align-left" style={style}>
      <p>
        <h1> Board v{video.boardVersion}</h1>
        <hr />
        <b>Timeframe: </b>
        {video.timeframe}
        <br />
        <b>Description: </b>
        {video.description}
        <br />
        <b>Improvements: </b>
        {video.improvements}
        <br />
        <b>Technologies used/learned: </b>
        {video.technologiesUsed}
        <hr />
        {console.log(video.videoId)}
        <YouTubeVideo videoId={video.videoId} />
      </p>
    </div>
  );
};

class Home extends React.Component {
  render() {
    const iterations = [];
    for (let video of videos) {
      iterations.push(
        <>
          <Iteration video={video} />
          <br />
          <br />
        </>
      );
    }

    return (
      <div>
        <MainBanner />
        <br />
        <br />
        {iterations}
        <br />
        <br />
        <Footer />
      </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default Home;
