import React from "react";

const subHeaderStyle = {
  // color: "black",
  // fontStyle: "italic",
};

const bannerStyle = {
  // background: "rgba(128, 128, 128, 0.45)",
  backgroundImage: "url('/pixel-pi-web-main-banner-background.gif')",
  backgroundSize: "cover",
  // height: "850px",
};

const MainBanner = (props) => {
  return (
    <div className="text-center align-middle" style={bannerStyle}>
      <div className="header container">
        <br />

        <h1>PixelPi</h1>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2 style={subHeaderStyle}>
          A Raspberry Pi audio spectrum analyzer and LED Matrix.
        </h2>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default MainBanner;
