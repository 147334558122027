import "./footer.css";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";


const footerStyle = {
  background: "rgba(32, 32, 32, 1)",
  color: "rgba(255, 255, 255, 1)",
};


const links = {
  codebase: [
    {
      desc: "Board v1 (software v1)",
      url: "https://www.youtube.com/watch?v=4KQzOLRS2EY",
    },
    {
      desc: "Board v2 (software v3a)",
      url: "https://www.youtube.com/watch?v=zVbTPZip60c",
    },
    {
      desc: "Board v3 (software v3)",
      url: "https://www.youtube.com/watch?v=jqmwNiv9g-A",
    },
    {
      desc: "Board v4 (software v3.1)",
      url: "https://www.youtube.com/watch?v=ZTfDSOyTaH8",
    }
  ]
};

class FooterLink extends React.Component {
  constructor(props) {
    super();
    this.state = {
      linkInfo: props.linkInfo,
      show: false,
      setShow: false,
    };
  }

  buildModalBodyText() {
    if (!!this.state.linkInfo.message) {
      return this.state.linkInfo.message;
    } else {
      return `${this.state.linkInfo.desc} link has not been added yet, please check back later.`;
    }
  }

  render() {
    const handleClose = () => {
      this.setState({ show: false });
    };
    const handleShow = () => {
      this.setState({ show: true });
    };

    return (
      <>
        <a onClick={handleShow} class="nav-link p-0 text-muted">
          {this.state.linkInfo.desc}
        </a>
        <Modal show={this.state.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.linkInfo.desc}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.buildModalBodyText()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class FooterLinks extends React.Component {
  buildFooterLink(linkInfo) {
    return (
      <li class="nav-item mb-2" style={{color: "rgba(255, 255, 255, 1)"}}>
        {linkInfo.url ? (
          <a href={linkInfo.url} class="nav-link p-0 text-muted" style={{color: "rgba(255, 255, 255, 1)"}}>
            {linkInfo.desc}
          </a>
        ) : (
          <FooterLink linkInfo={linkInfo} />
        )}
      </li>
    );
  }

  render() {
    return (
      <>
        <div class="col mb-3">
          <h5 style={{color: "rgba(255, 255, 255, 1)"}}>YouTube Demos</h5>
          <ul class="nav flex-column" >
            {links.codebase.map((link) => this.buildFooterLink(link))}
          </ul>
        </div>
      </>
    );
  }
}

const Footer = (props) => {
  return (
    <div style={footerStyle}>
      <div class="container">
        <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top">
          <div class="col mb-3">
            <a
              href="/"
              class="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >

            </a>
            <p class="text-muted">&copy; 2022</p>
          </div>

          <div class="col mb-3"></div>

          <FooterLinks />
        </footer>
      </div>
    </div>
  );
};

const subFooterStyle = {
  display: "flex !important;",
  justifyContent: "space-between",
};

export default Footer;
